import React from "react";

const Tag = () =>{

    return (
        <>
            <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                <article><div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg"><a href="/webinars/vantage-doers-webinar-series/above-beyond-the-spirit-of-reward-recognition/"><img className="p-0 rounded-lg webinar-video-thumb" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/12/doers-series-Menaka-Harjinder.png" alt="Above &amp; Beyond – The Spirit of Reward &amp; Recognition"/></a><a href="/webinars/vantage-doers-webinar-series/above-beyond-the-spirit-of-reward-recognition/"><div className="bg-white py-6 pb-2 h-full topcard no-br"><p className="text-orange text-base">[On-demand-recording]</p><h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3">Above &amp; Beyond – The Spirit of Reward &amp; Recognition</h2><p className="box-subtitle mb-2 uppercase">On <span>27th August, 2020</span></p><div className="with-sep text-gray-200 text-base">With</div><div className="md:flex justify-between"><div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Menaka Seetharaman</span> <br/> <span>Freelance HR Consultant</span></div><div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Harjinder Sidhu</span> <br/> <span>Independent Consultant</span></div></div></div></a></div></article>

                <article><div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg"><a href="/webinars/vantage-doers-webinar-series/rebooting-employee-recognition-programs-for-higher-impact/"><img className="p-0 rounded-lg webinar-video-thumb" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/12/Doesr-Series-Anjali-Saurabh.png" alt="Rebooting Employee Recognition Programs For Higher Impact"/></a><a href="/webinars/vantage-doers-webinar-series/rebooting-employee-recognition-programs-for-higher-impact/"><div className="bg-white py-6 pb-2 h-full topcard no-br"><p className="text-orange text-base">[On-demand-recording]</p><h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3">Rebooting Employee Recognition Programs For Higher Impact</h2><p className="box-subtitle mb-2 uppercase">On <span>14th July, 2020</span></p><div className="with-sep text-gray-200 text-base">With</div><div className="md:flex justify-between"><div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Anjali Chachra</span> <br/> <span>Independent HR Consultant</span></div><div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Saurabh Deshpande</span> <br/> <span>Freelance HR Business Partner</span></div></div></div></a></div></article>
            </div>
        </>

    );

}


export default Tag