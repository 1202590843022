import React from "react";

const Tag = () =>{

    return (
        <>
            <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                <article>
                    <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg">
                        <a href="/webinars/vantage-doers-webinar-series/corporate-wellness-in-the-new-world/"><img className="p-0 rounded-lg webinar-video-thumb" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/12/Doers-Series-Corporate-Wellness-in-the-New-World-Vantage-Circle.png" alt="Corporate Wellness in the New World" /></a>
                        <a href="/webinars/vantage-doers-webinar-series/corporate-wellness-in-the-new-world/">
                            <div className="bg-white py-6 pb-2 h-full topcard no-br">
                                <p className="text-orange text-base">[On-demand-recording]</p>
                                <h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3">Corporate Wellness in the New World</h2>
                                <p className="box-subtitle mb-2 uppercase">On <span>9th October, 2020</span></p>
                                <div className="with-sep text-gray-200 text-base">With</div>
                                <div className="md:flex justify-between">
                                <div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Padmajaa Iyer</span> <br/> <span>Head of Wellness, Mu Sigma</span></div>
                                <div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Saurabh Madan</span> <br/> <span>Communication Skills Coach</span></div>
                                </div>
                            </div>
                        </a>
                    </div>
                </article>
            </div>
        </>

    );

}


export default Tag