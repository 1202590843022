import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout';
import Helmet from "react-helmet"
import Covid19 from '../../components/Tags/Covid19';
import EmployeeEngagement from '../../components/Tags/EmployeeEngagement';
import RewardsRecognition from '../../components/Tags/RewardsRecognition';
import CorporateWellness from '../../components/Tags/CorporateWellness';
import WorkLife from '../../components/Tags/WorkLife';

export const query = graphql`
    query DoersWebinars {
        wpcontent {
            doersWebinars(first: 99) {
                nodes {
                    id
                    title
                    uri
                    webinardate
                    youtubeId
                    speaker1
                    speaker1Profile
                    speaker2
                    speaker2Profile
                    thumbnail
                }
            }
            seo{
                contentTypes {
                    doersWebinar {
                        metaDesc
                        title
                    }
                }
            }
            categories {
                nodes {
                  slug
                  name
                }
            }
        }
    }
`

const Home = ({ data,isEmpty }) => {
    const webinars = data.wpcontent.doersWebinars.nodes
    const webinarSeo = data.wpcontent.seo.contentTypes.doersWebinar
    const pageTitle = webinarSeo.title
    const metaDesc = webinarSeo.metaDesc
    return (
        <Layout>
            <Helmet>
                <title>{pageTitle}</title>

                <meta name="description" content={metaDesc} />

                <meta property="og:title" content={pageTitle} />

                <meta property="og:description" content={metaDesc} />

                <meta property="twitter:title" content={pageTitle} />
                        
                <meta property="twitter:description" content={metaDesc} />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://www.vantagecircle.com/webinars/vantage-doers-webinar-series/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <link rel="canonical" href="https://www.vantagecircle.com/webinars/vantage-doers-webinar-series/"></link>
            </Helmet>
            <section className="bg-purple-500  mx-auto py-10 pb-0 sm:py-20 sm:pb-0 md:py-20 relative mb-20">
                <div className="container md:flex md:flex-col justify-center items-center lg:flex-row lg:justify-between max-w-6xl text-center lg:text-left px-6 pb-10">
                   <div className = "w-full lg:w-1/2 justify-center lg:justify-start">
                        <h1 className = "text-2xl sm:text-3xl lg:text-7xl 2xl:text-7xl text-gray-100 font-bold">Doers' Webinars</h1>
                        <p className = "section-subtitle-white mt-3">Vantage Circle Doer's Webinar Series covers practical lessons, How-to guides, and tips & tactics to help you implement actionable employee engagement strategies at work.</p>
                   </div>
                   <div className = "w-full lg:w-1/2 flex justify-center md:justify-end items-center webinar-logo">
                        <img src = "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/12/vantage-doers-inverted.png" width="276" height="276" alt = "Doers' Webinars" />
                    </div>
                </div>
            </section>

            <section className="tagnames shadow-lg rounded-lg max-w-6xl p-5 relative z-1 bg-white md:-mt-40 mx-auto">
                <div className="section-title mt-0">Explore Webinar Topics</div>
                <div className="cat-button-wrap flex py-5 justify-start">
                    <div className="cat-button box-label mr-5 active" catId="cat1">
                        All Webinars
                    </div>
                    <div className="cat-button box-label mr-5" catId="cat2">
                        Covid-19 Resource
                    </div>
                    <div className="cat-button box-label mr-5" catId="cat3">
                        Employee Engagement
                    </div>
                    <div className="cat-button box-label mr-5" catId="cat4">
                        Rewards and Recognition
                    </div>
                    <div className="cat-button box-label mr-5" catId="cat5">
                        Corporate Wellness
                    </div>
                    <div className="cat-button box-label mr-5" catId="cat6">
                        Work-Life
                    </div>
                </div>
            </section>
            
            <section className="webinar-list container px-6 lg:px-0 max-w-6xl mx-auto pt-5 sm:pt-10 md:pt-20 pb-20 md:pb-40">
                <div class="cat-content">
                    <div class="cat-posts active" id="cat1">
                        <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                            {webinars.map(webinar => (
                                <article key={webinar.id}>
                                    <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg">
                                        <Link to={`${webinar.uri}`} >
                                            <img className="p-0 rounded-lg webinar-video-thumb" src={`${webinar.thumbnail}`} alt={webinar.title} />
                                        </Link>
                                        <Link to={`${webinar.uri}`} >
                                            <div className="bg-white py-6 pb-2 h-full topcard no-br">
                                                <p className="text-orange text-base">[On-demand-recording]</p>
                                                <h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3" dangerouslySetInnerHTML={{ __html: webinar.title }} />
                                                <p className="box-subtitle mb-2 uppercase">On <span dangerouslySetInnerHTML={{ __html: webinar.webinardate }} /></p>
                                                <div className="with-sep text-gray-200 text-base">With</div>
                                                <div className="md:flex justify-between">
                                                    <div className="box-subtitle w-fl md:h-12">
                                                        <span className="font-bold text-lg">{webinar.speaker1}</span> <br/> <span>{webinar.speaker1Profile}</span>
                                                    </div>
                                                    {webinar.speaker2 && <div className="box-subtitle w-fl md:h-12">
                                                        <span className="font-bold text-lg">{webinar.speaker2}</span> <br/> <span>{webinar.speaker2Profile}</span>
                                                    </div>}
                                                </div>
                                                
                                            </div>
                                        </Link>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>

                    <div class="cat-posts" id="cat2">
                        <Covid19 />
                    </div>
                    <div class="cat-posts" id="cat3">
                        <EmployeeEngagement />
                    </div>
                    <div class="cat-posts" id="cat4">
                        <RewardsRecognition />
                    </div>
                    <div class="cat-posts" id="cat5">
                        <CorporateWellness />
                    </div>
                    <div class="cat-posts" id="cat6">
                        <WorkLife />
                    </div>
                </div>
            </section>
            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className=" p-10 md:p-14 relative z-9 overflow-hidden" style={{"background": "#414171"}}>
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
        </Layout>
    )
}

export default Home