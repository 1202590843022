import React from "react";

const Tag = () =>{

    return (
        <>
            <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                <article>
                    <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg">
                        <a href="/webinars/vantage-doers-webinar-series/is-hr-preparing-for-what-life-will-be-like-after-covid-19/"><img className="p-0 rounded-lg webinar-video-thumb" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/12/Doers-Series-Is-HR-Preparing-for-What-Life-Will-Be-Like-After-COVID-19-Vantage-Cricle.png" alt="Is HR Preparing for What Life Will Be Like After COVID-19?" /></a>
                        <a href="/webinars/vantage-doers-webinar-series/is-hr-preparing-for-what-life-will-be-like-after-covid-19/">
                            <div className="bg-white py-6 pb-2 h-full topcard no-br">
                                <p className="text-orange text-base">[On-demand-recording]</p>
                                <h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3">Is HR Preparing for What Life Will Be Like After COVID-19?</h2>
                                <p className="box-subtitle mb-2 uppercase">On <span>12th July, 2021</span></p>
                                <div className="with-sep text-gray-200 text-base">With</div>
                                <div className="md:flex justify-between">
                                <div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Alec Bashinsky</span> <br/> <span>APAC Partner, Josh Bersin Academy, AU</span></div>
                                </div>
                            </div>
                        </a>
                    </div>
                </article>
                <article>
                    <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg">
                        <a href="/webinars/vantage-doers-webinar-series/driving-employee-engagement-in-the-covid-19-impacted-work-environment/"><img className="p-0 rounded-lg webinar-video-thumb" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2021/12/Doesr-Series-Anjali-Saurabh.png" alt="Driving Employee Engagement in the COVID-19 Impacted Work Environment"/></a>
                        <a href="/webinars/vantage-doers-webinar-series/driving-employee-engagement-in-the-covid-19-impacted-work-environment/">
                            <div className="bg-white py-6 pb-2 h-full topcard no-br">
                                <p className="text-orange text-base">[On-demand-recording]</p>
                                <h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3">Driving Employee Engagement in the COVID-19 Impacted Work Environment</h2>
                                <p className="box-subtitle mb-2 uppercase">On <span>24th June, 2020</span></p>
                                <div className="with-sep text-gray-200 text-base">With</div>
                                <div className="md:flex justify-between">
                                <div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Anjali Chachra</span> <br/> <span>Independent HR Consultant</span></div>
                                <div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Saurabh Deshpande</span> <br/> <span>Freelance HR Business Partner</span></div>
                                </div>
                            </div>
                        </a>
                    </div>
                </article>
                <article><div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg"><a href="/webinars/vantage-doers-webinar-series/psychological-impact-of-covid-19-on-employees/"><img className="p-0 rounded-lg webinar-video-thumb" src="null" alt="Psychological Impact of Covid-19 on Employees" /></a><a href="/webinars/vantage-doers-webinar-series/psychological-impact-of-covid-19-on-employees/"><div className="bg-white py-6 pb-2 h-full topcard no-br"><p className="text-orange text-base">[On-demand-recording]</p><h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3">Psychological Impact of Covid-19 on Employees</h2><p className="box-subtitle mb-2 uppercase">On <span>5th May, 2020</span></p><div className="with-sep text-gray-200 text-base">With</div><div className="md:flex justify-between"><div className="box-subtitle w-fl md:h-12"><span className="font-bold text-lg">Dr. Varsha Sreevatsa</span> <br/> <span>Ph. D in Psychology</span></div></div></div></a></div></article>
            </div>
        </>

    );

}


export default Tag